import { m, useInView } from "framer-motion";
import { useRef } from "react";


const StepByStep = () => {
    const conRef = useRef(null)
    const isInView = useInView(conRef)

    
    return <>
        <section className="my-[100px] max-w-[1600px] relative">
            <div id="stepbystep" className="absolute top-[-180px]"></div>
            <div ref={conRef} className="flex flex-col">
                <div className="w-full flex items-center justify-center text-center">
                    <m.div
                        initial={{scale: 2, opacity: 0}}
                        animate={isInView ? {scale: 1, opacity: 1} : {}}
                        transition={{ duration: 0.6, type: "spring" }}
                    >
                        <div className="flex items-center justify-center gap-4 max-lg:gap-3 max-md:flex-col max-md:gap-3">
                            <h2 className="font-bold whitespace-nowrap text-[5rem] max-xl:text-[4rem] max-lg:text-[3rem] max-md:text-[2rem] max-md:leading-[20px]">How</h2>
                            <img className="max-w-[400px] max-xl:max-w-[300px] max-lg:max-w-[250px]" src="/assets/Logo Asset 3.png" alt="" />
                            <h2 className="font-bold whitespace-nowrap text-[5rem] max-xl:text-[4rem] max-lg:text-[3rem] max-md:text-[2rem] max-md:leading-[20px]">Works</h2>
                        </div>

                        <h1 className="max-md:mt-5" >Step-by-Step Process</h1>
                    </m.div>
                </div>
                <div className="mt-12 w-full">
                    <div className="flex flex-wrap w-fit m-auto max-lg:flex-col gap-x-10">
                        <div className="py-[30px] px-[10px] max-w-[280px] max-lg:max-w-3xl max-lg:m-auto text-[1.2rem]">
                            <div className="w-fit m-auto relative" >
                                <div style={{backgroundImage: 'url(./assets/step1.png)'}} className="z-[999] bg-cover flex items-center justify-center h-[200px] w-[200px] bg-trasparent text-gold-300 m-auto"></div>
                            </div>
                            <p className="text-center mt-6 text-black-100"><span className="text-gold-300" >Initiate Login or Transaction:</span> The user begins a login or transaction on a platform integrated with CoinSafe.</p>
                        </div>

                        <div className="py-[30px] px-[10px] max-w-[280px] max-lg:max-w-3xl max-lg:m-auto text-[1.2rem]">
                            <div className="w-fit m-auto relative" >
                                <div style={{backgroundImage: 'url(./assets/step2.png)'}} className="z-[999] bg-cover flex items-center justify-center h-[200px] w-[200px] bg-trasparent text-gold-300 m-auto"></div>
                            </div>
                            <p className="text-center mt-6 text-black-100"><span className="text-gold-300" >Approve with One Tap:</span> The platform sends an authentication request, which is transmitted via the Sui blockchain to the user's CoinSafe app. The user receives a notification and approves or denies the request with a single tap, secured by cryptographic signatures.</p>
                        </div>
                        <div className="py-[30px] px-[10px] max-w-[280px] max-lg:max-w-3xl max-lg:m-auto text-[1.2rem]">
                            <div className="w-fit m-auto relative" >
                                <div style={{backgroundImage: 'url(./assets/step3.png)'}} className="z-[999] bg-cover flex items-center justify-center h-[200px] w-[200px] bg-trasparent text-gold-300 m-auto"></div>
                            </div>
                            <p className="text-center mt-6 text-black-100"><span className="text-gold-300" >Securely Complete via Blockchain:</span> Upon the user's approval, the Sui blockchain verifies and finalizes the login or transaction, sending confirmation back to the platform and ensuring full security, transparency, and protection against attacks.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default StepByStep