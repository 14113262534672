import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App/App'
import { BrowserRouter } from 'react-router-dom'


const ele = document.createElement('div')
ele.setAttribute('class', 'w-full')

//background: linear-gradient(45deg, #00c5ff, transparent);

document.body.appendChild(ele)

const root = ReactDOM.createRoot(ele)


root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)