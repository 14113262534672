import Footer from "../Footer/Footer";
import { LazyMotion, domAnimation } from "framer-motion";
import { Sidebar } from "flowbite-react";
import Header from "./components/Header/Header";

const sidebarTheme = {
    "root": {
      "base": "h-full",
      "collapsed": {
        "on": "w-16",
        "off": "w-fit"
      },
      "inner": "h-full overflow-y-auto overflow-x-hidden bg-[#0a0a0a] px-3 py-4 dark:bg-gray-800"
    },
    "collapse": {
      "button": "group flex w-full items-center rounded-lg p-2 text-base font-normal text-white transition duration-75 hover:bg-[#1a1a1a] dark:text-white dark:hover:bg-gray-700",
      "icon": {
        "base": "h-6 w-6 text-white transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white",
        "open": {
          "off": "",
          "on": "text-white"
        }
      },
      "label": {
        "base": "ml-3 flex-1 whitespace-nowrap text-left",
        "icon": {
          "base": "h-6 w-6 transition delay-0 ease-in-out",
          "open": {
            "on": "rotate-180",
            "off": ""
          }
        }
      },
      "list": "space-y-2 py-2"
    },
    "cta": {
      "base": "mt-6 rounded-lg bg-gray-100 p-4 dark:bg-gray-700",
      "color": {
        "blue": "bg-cyan-50 dark:bg-cyan-900",
        "dark": "bg-dark-50 dark:bg-dark-900",
        "failure": "bg-red-50 dark:bg-red-900",
        "gray": "bg-alternative-50 dark:bg-alternative-900",
        "green": "bg-green-50 dark:bg-green-900",
        "light": "bg-light-50 dark:bg-light-900",
        "red": "bg-red-50 dark:bg-red-900",
        "purple": "bg-purple-50 dark:bg-purple-900",
        "success": "bg-green-50 dark:bg-green-900",
        "yellow": "bg-yellow-50 dark:bg-yellow-900",
        "warning": "bg-yellow-50 dark:bg-yellow-900"
      }
    },
    "item": {
      "base": "max-w-[300px]  flex items-center justify-center rounded-lg p-2 text-base font-normal text-white hover:bg-[#1a1a1a] dark:text-white dark:hover:bg-gray-700",
      "active": "bg-gray-100 dark:bg-gray-700",
      "collapsed": {
        "insideCollapse": "group w-full pl-8 transition duration-75",
        "noIcon": "font-bold"
      },
      "content": {
        "base": "flex-1 whitespace-wrap px-3"
      },
      "icon": {
        "base": "h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
        "active": "text-gray-700 dark:text-gray-100"
      },
      "label": "",
      "listItem": ""
    },
    "items": {
      "base": ""
    },
    "itemGroup": {
      "base": "mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700"
    },
    "logo": {
      "base": "mb-5 flex items-center pl-2.5",
      "collapsed": {
        "on": "hidden",
        "off": "self-center whitespace-nowrap text-xl font-semibold dark:text-white"
      },
      "img": "mr-3 h-6 sm:h-7"
    }
}


const Docs = () => {
    return <>
        <div className="w-full">
            <LazyMotion features={domAnimation} strict>
                <Header/>
                <main className="w-full">
                    <div className="max-w-7xl mx-auto w-full flex flex-col">
                        <div className="w-full h-full flex">
                            <div className="sticky top-[64px] h-[100vh] max-md:hidden">
                                <Sidebar theme={sidebarTheme} aria-label="CoinSafe">
                                    <Sidebar.Items>
                                        <Sidebar.ItemGroup>
                                            <Sidebar.Item href="#overview">
                                                Overview
                                            </Sidebar.Item>
                                            <Sidebar.Collapse label="Components of the System" href="#components-of-the-system#platform">
                                                <Sidebar.Item href="#platform">Platform</Sidebar.Item>
                                                <Sidebar.Item href="#coinsafe-api">CoinSafe API</Sidebar.Item>
                                                <Sidebar.Item href="#sui-blockchain">Sui Blockchain</Sidebar.Item>
                                                <Sidebar.Item href="#smart-contracts">Smart Contracts</Sidebar.Item>
                                                <Sidebar.Item href="#decentralized-identifier">Decentralized Identifier</Sidebar.Item>
                                                <Sidebar.Item href="#12-word-seed-phrase">12-Word Seed Phrase</Sidebar.Item>
                                                <Sidebar.Item href="#coinsafe-app">CoinSafe App</Sidebar.Item>
                                            </Sidebar.Collapse>
                                            <Sidebar.Item href="#full-system-flow">
                                                Full System Flow
                                            </Sidebar.Item>
                                            <Sidebar.Item href="#linking-process-with-external-platforms">
                                                Linking Process with External Platforms
                                            </Sidebar.Item>
                                            <Sidebar.Collapse label="Security Considerations">
                                                <Sidebar.Item href="#security-decentralized-identifiers">Decentralized Identifiers</Sidebar.Item>
                                                <Sidebar.Item href="#security-12-word-seed-phrase">12-Word Seed Phrase</Sidebar.Item>
                                                <Sidebar.Item href="#security-authorization-tokens">Authorization Tokens</Sidebar.Item>
                                                <Sidebar.Item href="#security-cryptographic-signatures">Cryptographic Signatures</Sidebar.Item>
                                                <Sidebar.Item href="#security-rate-limiting">Rate Limiting and Transaction Monitoring</Sidebar.Item>
                                            </Sidebar.Collapse>
                                        </Sidebar.ItemGroup>
                                    </Sidebar.Items>
                                </Sidebar>
                            </div>
                            <article className="w-full mb-20" >
                                <div className="px-6">
                                    <div className="flex justify-center w-full mt-10">
                                        <h2 className="text-[2.5rem] max-xl:text-[1.6rem] text-center max-w-[800px] max-xl:max-w-[550px] mx-2">CoinSafe: Technical Architecture and Full System Flow</h2>
                                    </div>
                                    <div className="relative">
                                        <div id="overview" className="absolute -mt-20"></div>
                                        
                                        <h2 className="font-semibold tracking-tight mt-10 border-b pb-1 text-3xl border-gold-300">Overview</h2>
                                        <p className="leading-7 mt-6">
                                            CoinSafe is a <strong>decentralized Multi-Factor Authentication (MFA)</strong> solution powered by the <strong>Sui
                                            blockchain</strong>. It allows users to securely approve or deny login and transaction requests by
                                            interacting with smart contracts on the blockchain via a user-friendly mobile app.
                                        </p>
                                        <p className="leading-7 mt-6">
                                            CoinSafe is designed to prevent phishing, SIM-swap attacks, and unauthorized access through
                                            the use of cryptographic signatures and <strong>blockchain verification</strong>. The system employs
                                            Decentralized Identifiers (DID), giving users complete control over their identities. A <strong>12-word
                                            seed phrase</strong> is provided during setup to enable recovery in case of lost or stolen devices.
                                        </p>
                                    </div>
                                    
                                    <div >
                                        <h2 className="font-semibold tracking-tight mt-10 border-b pb-1 text-3xl border-gold-300">Components of the System</h2>
                                        <ol className="mt-6 ml-6 list-decimal">
                                            <div className="relative">
                                                <div id="platform" className="absolute -mt-20"></div>

                                                <li className="my-3">
                                                    <strong>Platform</strong>: A service (e.g., cryptocurrency exchange, fintech platform) that integrates with CoinSafe
                                                    to request user authentication.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="coinsafe-api" className="absolute -mt-20"></div>

                                                <li className="my-3">
                                                    <strong>CoinSafe API</strong>: Acts as an intermediary between external platforms and the Sui blockchain. It processes
                                                    requests, generates blockchain transactions, and handles push notifications sent to
                                                    users.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="sui-blockchain" className="absolute -mt-20"></div>

                                                <li className="my-3">
                                                    <strong>Sui Blockchain</strong>: A decentralized, tamper-proof blockchain that hosts CoinSafe’s smart contracts. It
                                                    verifies cryptographic signatures, logs authentication requests, and records the outcome
                                                    (approved or denied).
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="smart-contracts" className="absolute -mt-20"></div>

                                                <li className="my-3">
                                                    <strong>Smart Contracts</strong>: Deployed on the Sui blockchain to perform cryptographic signature verification and
                                                    record transaction outcomes.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="decentralized-identifier" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>Decentralized Identifier (DID)</strong>: A DID is a self-sovereign identity unique to each user, stored on the Sui blockchain.
                                                    It’s linked to the user’s public key, and each authentication request is associated with
                                                    the user’s DID. This eliminates the need for centralized identity management.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="12-word-seed-phrase" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>12-Word Seed Phrase</strong>: Upon setup, users are provided with a 12-word seed phrase that allows them to recover
                                                    their CoinSafe account and private key in case of device loss or switching devices. The
                                                    seed phrase can be used to restore access to the CoinSafe app on a new device,
                                                    enabling users to continue approving requests without losing access to their
                                                    authentication history.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="coinsafe-app" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>CoinSafe App</strong>: A mobile app that allows users to receive push notifications and approve/deny login and
                                                    transaction requests. The app stores the user’s private key locally (secured via
                                                    encryption) and generates cryptographic signatures for approval requests. It is the
                                                    primary interface for interacting with CoinSafe’s MFA solution.
                                                </li>
                                            </div>
                                        </ol>
                                    </div>
                                    <div className="relative">
                                        <div id="full-system-flow" className="absolute -mt-20"></div>

                                        <h2 className="font-semibold tracking-tight mt-10 border-b pb-1 text-3xl border-gold-300">Full System Flow</h2>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 1: User Action (Login/Transaction Request)</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The user initiates a login or transaction on a platform integrated with CoinSafe (e.g., Coinbase or a financial platform)
                                            </li>
                                            <li className="my-3">
                                                The platform collects and sends the following details to the <strong>CoinSafe API</strong>:
                                                <ol className="ml-6 list-[circle]" >
                                                    <li className="my-2"><strong>User DID</strong> (Decentralized Identifier)</li>
                                                    <li className="my-2"><strong>Request Type</strong>: Login or transaction</li>
                                                    <li className="my-2"><strong>Request Details</strong>: Transaction amount, platform name, etc.</li>
                                                    <li className="my-2"><strong>Device Information</strong>: IP address, browser type, OS, etc.</li>
                                                    <li className="my-2"><strong>Timestamp</strong>: To prevent replay attacks.</li>
                                                </ol>
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 2: Platform Sends Request to CoinSafe API</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The platform sends the collected data to the <strong>CoinSafe API</strong>.
                                            </li>
                                            <li className="my-3">
                                                The API processes the request and prepares a transaction for submission to the <strong>Sui blockchain</strong>.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 3: CoinSafe API Sends Transaction to Sui Blockchain</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The CoinSafe API creates a <strong>transaction</strong> on the <strong>Sui blockchain</strong> that includes the user’s
                                                <strong>DID</strong>, the <strong>hashed request details</strong>, and any necessary metadata for verification.
                                            </li>
                                            <li className="my-3">
                                                This transaction is logged on the blockchain, triggering the authentication process.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 4: Notification Triggered by CoinSafe API</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The <strong>CoinSafe API</strong> sends a <strong>push notification</strong> to the user’s CoinSafe app informing 
                                                them of the authentication request. The notification includes:
                                            </li>
                                            <ol className="ml-6 list-[circle]" >
                                                <li className="my-2"><strong>Request Type</strong> (Login/Transaction)</li>
                                                <li className="my-2"><strong>Device Information</strong> (IP address, browser type, etc.)</li>
                                                <li className="my-2"><strong>Platform Name</strong> (e.g., Coinbase)</li>
                                            </ol>
                                            <li className="my-3">
                                                The notification is sent via:
                                            </li>
                                            <ol className="ml-6 list-[circle]" >
                                                <li className="my-2"><strong>Firebase Cloud Messaging</strong> (for Android)</li>
                                                <li className="my-2"><strong>APNS</strong>  (for iOS)</li>
                                            </ol>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 5: User Approves/Denies Request in CoinSafe App</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The user opens the <strong>CoinSafe app</strong> to review the request details.
                                            </li>
                                            <li className="my-3">
                                                The app displays information pulled from the <strong>CoinSafe API</strong> and <strong>Sui blockchain</strong>:
                                            </li>
                                            <ol className="ml-6 list-[circle]" >
                                                <li className="my-2"><strong>Request Type</strong></li>
                                                <li className="my-2"><strong>Device Information</strong></li>
                                                <li className="my-2"><strong>Transaction Amount</strong> (if applicable)</li>
                                            </ol>
                                            <li className="my-3">
                                                The user chooses to either approve or deny the request.
                                            </li>
                                            <ol className="ml-6 list-[circle]" >
                                                <li className="my-2"> If approved, the app generates a <strong>cryptographic signature</strong> using the user’s
                                                private key and sends it as a new transaction to the <strong>Sui blockchain</strong>.</li>
                                                <li className="my-2"><strong>Device Information</strong></li>
                                                <li className="my-2">If denied, no transaction is sent, or a <strong>denial transaction</strong> is logged.</li>
                                            </ol>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 6: Smart Contract Verifies Signature on Sui Blockchain</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The smart contract on the <strong>Sui blockchain</strong> verifies the cryptographic signature against the user’s <strong>public key</strong> (linked to their DID).
                                            </li>
                                            <li className="my-3">
                                                If the signature is valid, the smart contract logs the approval.
                                            </li>
                                            <li className="my-3">
                                                If the signature is invalid, the request is rejected.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 7: CoinSafe API Retrieves Outcome from the Blockchain</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The <strong>CoinSafe API</strong> monitors the blockchain for the outcome of the user’s approval or denial.
                                            </li>
                                            <li className="my-3">
                                                Once the transaction is confirmed, the API updates the request status and logs the outcome (approved/denied).
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 8: API Sends Response to Platform</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The <strong>CoinSafe API</strong> sends the final response (approved/denied) to the platform.
                                            </li>
                                            <li className="my-3">
                                                The platform takes action accordingly:
                                            </li>
                                            <ol className="ml-6 list-[circle]" >
                                                <li className="my-2"><strong>If approved</strong>, the login/transaction is allowed.</li>
                                                <li className="my-2"><strong>If denied</strong>, the login/transaction is blocked.</li>
                                            </ol>
                                        </ol>
                                    </div>

                                    <div className="relative">
                                        <div id="linking-process-with-external-platforms" className="absolute -mt-20"></div>

                                        <h2 className="font-semibold tracking-tight mt-10 border-b pb-1 text-3xl border-gold-300">Linking Process with External Platforms (e.g., Coinbase)</h2>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 1: Initial Linking Request on Coinbase</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                When the user clicks "<strong>Link with CoinSafe</strong>" on <strong>Coinbase</strong>, they must already be
                                                authenticated on Coinbase. This allows Coinbase to generate a linking request
                                                containing the user's <strong>Coinbase User ID</strong> (or equivalent identifier) and device metadata.
                                            </li>
                                            <li className="my-3">
                                                Coinbase sends this request to the <strong>CoinSafe API</strong> for processing.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 2: User Authenticates via CoinSafe App</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                To ensure the CoinSafe API knows which CoinSafe app is associated with the user on
                                                Coinbase, the user must authenticate in the app through one of two methods:
                                            </li>
                                            <ol className="ml-6 list-[circle]" >
                                                <li className="my-2">
                                                    <strong>Option 1: Link via QR Code or One-Time Code</strong>: Coinbase generates a QR
                                                    code or one-time linking code for the user to scan or input into their CoinSafe
                                                    app, securely linking the two accounts.
                                                </li>
                                                <li className="my-2">
                                                    <strong>Option 2: OAuth Authentication</strong>: The user is redirected to the CoinSafe app or
                                                    authentication page to log into their CoinSafe account and approve the linking
                                                    request.
                                                </li>
                                            </ol>
                                            <li className="my-3">
                                                Coinbase sends this request to the <strong>CoinSafe API</strong> for processing.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 3: Push Notification Sent to CoinSafe App</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                After the linking request is processed, the <strong>CoinSafe API</strong> sends a push notification to the
                                                user’s app asking them to approve the request to link their Coinbase account.
                                            </li>
                                        </ol>
                                        
                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 4: User Approves the Linking Request in CoinSafe App</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The user opens the app and approves the linking request, generating a <strong>cryptographic
                                                signature</strong> that is sent to the <strong>Sui blockchain</strong>.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 5: Smart Contract Verifies the Signature</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                The <strong>Sui blockchain</strong> verifies the signature and logs the successful link between the
                                                user’s CoinSafe and Coinbase accounts.
                                            </li>
                                        </ol>

                                        <h1 className="font-semibold tracking-tight mt-5 pb-1 text-1xl">Step 6: CoinSafe API Sends Response to Coinbase</h1>
                                        <ol className="mt-1 ml-6 list-disc">
                                            <li className="my-3 mt-1">
                                                After successful verification, the <strong>CoinSafe API</strong> sends a confirmation to Coinbase that
                                                the account has been linked, enabling <strong>CoinSafe MFA</strong> for the user.
                                            </li>
                                        </ol>
                                    </div>
                                    <div>
                                        <h2 className="font-semibold tracking-tight mt-10 border-b pb-1 text-3xl border-gold-300">Security Considerations</h2>
                                        <ol className="mt-6 ml-6 list-decimal">
                                            <div className="relative">
                                                <div id="security-decentralized-identifiers" className="absolute -mt-20"></div>

                                                <li className="my-3">
                                                    <strong>Decentralized Identifiers (DID)</strong>: Users are represented by <strong>DIDs</strong> stored on the Sui blockchain, giving them full control
                                                    over their identities without relying on centralized management.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="security-12-word-seed-phrase" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>12-Word Seed Phrase for Account Recovery</strong>: Users can restore their CoinSafe account and private key using their <strong>12-word seed
                                                    phrase</strong> in case they lose access to their device.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="security-authorization-tokens" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>Authorization Tokens</strong>: API requests between platforms and CoinSafe must include secure authorization tokens
                                                    (e.g., OAuth 2.0 or API keys) to ensure only authorized platforms can communicate with
                                                    the CoinSafe API.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="security-cryptographic-signatures" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>Cryptographic Signatures</strong>: Every user approval/denial generates a <strong>cryptographic signature</strong> using their private
                                                    key, which is verified against the blockchain to ensure authenticity.
                                                </li>
                                            </div>
                                            <div className="relative">
                                                <div id="security-rate-limiting" className="absolute -mt-20"></div>
                                                <li className="my-3">
                                                    <strong>Rate Limiting and Transaction Monitoring</strong>: The CoinSafe API implements rate-limiting and monitors transactions for abuse or
                                                    unauthorized activity to prevent denial-of-service attacks.
                                                </li>
                                            </div>
                                        </ol>
                                    </div>
                                </div>
                            </article>
                            
                        </div>
                    </div>
                </main>
                <Footer/>
            </LazyMotion>
        </div>
    </>
}

export default Docs