import { m } from "framer-motion";


const Hero = () => {

    return <>
        <section id="hero" className="py-[100px] mb-[100px] max-lg:py-[50px]">
            <div className="full flex justify-center max-xl:px-4">

                <div className="w-fit rounded-lg ring-2 ring-gray-800 p-[3px] relative">
                    <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden">
                        <div style={{background: 'radial-gradient(#f4a23033 0,transparent 60%)'}} className="absolute w-[700px] h-[700px] rounded-full bottom-[-80px] left-[0px] max-md:left-[-80px] pointer-events-none z-[-1]"></div>
                    </div>
                    <div className="flex flex-col py-[60px] w-fit backdrop-blur-[80px]">
                        <div className="flex flex-row">
                            <div className="flex flex-col items-center text-center w-fit">
                                <m.div
                                    className="max-xl:px-5 max-w-4xl max-lg:max-w-2xl max-lg:max-w-md max-xl:max-w-lg font-bold"
                                    initial={{x: '-1000px', opacity: 0}} animate={{x: '0px', opacity: 1}} transition={{ duration: 0.6, type: "spring" }}
                                >
                                    <h1 className="text-[4.5rem] max-xl:text-[3rem] max-xl:leading-[65px] max-lg:text-[2.8rem] max-lg:leading-[55px] max-md:text-[1.5rem] max-md:leading-[35px] leading-[85px] max-md:leading-[55px]">Revolutionizing MFA with Blockchain</h1>
                                </m.div>

                                <m.div
                                    className="pt-5 text-[1.5rem] max-xl:px-5 max-xl:text-[1rem] max-w-2xl"
                                    initial={{x: '1000px', opacity: 0}} animate={{x: '0px', opacity: 1}} transition={{ duration: 0.6, type: "spring" }}
                                >
                                    The most secure, user-friendly multi-factor authentication powered by the Sui blockchain.
                                </m.div>
                                {/*
                                <div className="pt-10 gap-4 flex items-center justify-center">
                                    <button className="bg-primaryblue-300 hover:bg-primaryblue-250 active:bg-primaryblue-200 px-5 py-3 text-lg text-white rounded-lg buttonShadow" >Get Started</button>
                                    <button className="bg-black hover:bg-gray-900 active:bg-gray-800 px-5 py-3 text-lg text-white rounded-lg buttonShadow" >Request a Demo</button>
                                </div>
                                */}

                            </div>
                        </div>
                        <div className="flex justify-center w-full mt-[50px]" >
                            <m.div
                                className="flex gap-x-6 text-black-100 max-xl:flex-col max-xl:gap-y-3 max-md:text-[.8rem] justify-start items-start"
                                initial={{y: '100px', opacity: 0}}
                                animate={{y: '0px', opacity: 1}}
                                transition={{ duration: 0.6, type: "spring" }}
                            >
                                <div className="flex gap-1 items-center justify-center">
                                    <svg className="h-[22px] text-gold-300" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Trojan_horse">
                                            <path d="M458.5,391.0154H437.5293a64.4033,64.4033,0,0,1,18.2681,45H458.5a22.5,22.5,0,0,0,22.5-22.5v0A22.5,22.5,0,0,0,458.5,391.0154Z" />
                                            <circle cx="391.001" cy="435.9846" r={45} />
                                            <path d="M185.7974,436.0154H326.1982a64.388,64.388,0,0,1,18.2725-45H167.5293A64.4033,64.4033,0,0,1,185.7974,436.0154Z" />
                                            <circle cx="121.001" cy="435.9846" r={45} />
                                            <path d="M31,413.5149v0a22.5,22.5,0,0,0,22.5,22.5h2.6982a64.388,64.388,0,0,1,18.2725-45H53.5A22.5,22.5,0,0,0,31,413.5149Z" />
                                            <path d="M428.8018,210.9856v64.7993l27.7695,27.77A9.0377,9.0377,0,0,0,472,297.1643V204.3088c0-29.9082-19.897-57.1181-49.062-63.7446a62.2407,62.2407,0,0,0-34.5615,1.94A78.34,78.34,0,0,1,428.8018,210.9856Z" />
                                            <path d="M103,373.825a62.1777,62.1777,0,0,1,18-2.61,64.7618,64.7618,0,0,1,29.9707,7.38l19.8833-82.8111a22.5272,22.5272,0,0,1,21.9053-17.2685H319.2407a22.5272,22.5272,0,0,1,21.9053,17.2685l19.8833,82.8111A64.7483,64.7483,0,0,1,391,371.2146a62.2,62.2,0,0,1,18,2.61v-162.81a58.5,58.5,0,0,0-58.5-58.5h-126A121.4526,121.4526,0,0,0,103,31.0154H62.5391A22.5386,22.5386,0,0,0,40,53.554V179.5154H76l27-27Z" />
                                        </g>
                                    </svg>
                                    <span>Phishing-resistant MFA</span>
                                </div>
                                <div className="flex gap-1 items-center justify-center">
                                    <svg className="h-[22px] text-gold-300" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 297.961 297.961" xmlSpace="preserve">
                                        <path d="M235.443,4.316C234.064,1.675,231.331,0,228.35,0H111.391c-3.537,0-6.654,2.341-7.666,5.729l-41.78,140.009
                                        c-0.723,2.422-0.259,5.029,1.251,7.055c1.51,2.027,3.889,3.207,6.415,3.207h65.579l-26.419,132.38
                                        c-0.769,3.85,1.369,7.697,5.047,9.07c0.917,0.342,1.863,0.511,2.796,0.511c2.806,0,5.496-1.48,6.954-4.04l103.03-180.928
                                        c1.41-2.477,1.396-5.533-0.036-7.997c-1.432-2.464-4.066-3.996-6.916-3.996h-46.392l61.658-88.415
                                        C236.617,10.14,236.822,6.959,235.443,4.316z" />
                                    </svg>
                                    <span>Powered by Sui Blockchain</span>
                                </div>
                                <div className="flex gap-1 items-center justify-center">
                                    <svg className="h-[22px] text-gold-300" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z" fill="currentColor" />
                                        <path d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z" fill="currentColor" />
                                    </svg>
                                    <span>Seamless User Experience</span>
                                </div>
                            </m.div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
}

export default Hero