import { useEffect, useState } from "react"
import { m } from "framer-motion";
import { Sidebar } from "flowbite-react";


const menu = {
    open: {
        maxHeight: '100%',
        opacity: 1,
        borderRadius: '0px 0px 0% 0%',
        transition: {
            duration: .2,
            ease: "easeOut"
        }
    },
    closed: {
        maxHeight: '0px',
        opacity: .2,
        borderRadius: '0px 0px 100% 100%',
        transition: {
            duration: .1,
            ease: "easeOut"
        }
    }
}

const sidebarTheme = {
    "root": {
      "base": "w-full h-full pt-[54px]",
      "collapsed": {
        "on": "w-16",
        "off": "w-full"
      },
      "inner": "h-full overflow-y-auto overflow-x-hidden bg-[#0a0a0a] px-3 py-4 dark:bg-gray-800"
    },
    "collapse": {
      "button": "group flex w-full items-center rounded-lg p-2 text-base font-normal text-white transition duration-75 hover:bg-[#1a1a1a] dark:text-white dark:hover:bg-gray-700",
      "icon": {
        "base": "h-6 w-6 text-gray-500 transition duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white",
        "open": {
          "off": "",
          "on": "text-white"
        }
      },
      "label": {
        "base": "ml-3 flex-1 whitespace-nowrap text-left",
        "icon": {
          "base": "h-6 w-6 transition delay-0 ease-in-out",
          "open": {
            "on": "rotate-180",
            "off": ""
          }
        }
      },
      "list": "space-y-2 py-2"
    },
    "cta": {
      "base": "mt-6 rounded-lg bg-gray-100 p-4 dark:bg-gray-700",
      "color": {
        "blue": "bg-cyan-50 dark:bg-cyan-900",
        "dark": "bg-dark-50 dark:bg-dark-900",
        "failure": "bg-red-50 dark:bg-red-900",
        "gray": "bg-alternative-50 dark:bg-alternative-900",
        "green": "bg-green-50 dark:bg-green-900",
        "light": "bg-light-50 dark:bg-light-900",
        "red": "bg-red-50 dark:bg-red-900",
        "purple": "bg-purple-50 dark:bg-purple-900",
        "success": "bg-green-50 dark:bg-green-900",
        "yellow": "bg-yellow-50 dark:bg-yellow-900",
        "warning": "bg-yellow-50 dark:bg-yellow-900"
      }
    },
    "item": {
      "base": "w-full text-left flex items-center justify-center rounded-lg p-2 text-base font-normal text-white hover:bg-[#1a1a1a] dark:text-white dark:hover:bg-gray-700",
      "active": "bg-[#ffffffe3] dark:bg-gray-700",
      "collapsed": {
        "insideCollapse": "group w-full pl-8 transition duration-75",
        "noIcon": "font-bold"
      },
      "content": {
        "base": "flex-1 whitespace-wrap px-3"
      },
      "icon": {
        "base": "h-6 w-6 flex-shrink-0 text-white transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white",
        "active": "text-gray-700 dark:text-gray-100"
      },
      "label": "",
      "listItem": ""
    },
    "items": {
      "base": ""
    },
    "itemGroup": {
      "base": "mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700"
    },
    "logo": {
      "base": "mb-5 flex items-center pl-2.5",
      "collapsed": {
        "on": "hidden",
        "off": "self-center whitespace-nowrap text-xl font-semibold dark:text-white"
      },
      "img": "mr-3 h-6 sm:h-7"
    }
}

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const handleItemClick = () => {
        setMenuOpen(false)
    }

    useEffect( () => {
        if( menuOpen ) {
            document.body.style.overflow = 'hidden'

        } else {
            document.body.style.overflow = ''
        }
    }, [menuOpen] )

    return <>
        <header className={`w-full py-3 px-12 z-[9999] sticky top-0 bg-[#06060659] backdrop-blur-sm`} >
            <m.div
                className="max-w-7xl mx-auto flex justify-between"
                initial={{y: '-100px', opacity: 0}}
                animate={{y: '0px', opacity: 1}}
                transition={{ duration: 0.8, type: "spring" }}
            >
                <a className="z-[999]" href="https://coinsafe.ai">
                    <img className="max-h-[40px] max-md:max-h-[40px] max-sm:max-h-[30px] transition-all" src="./assets/Logo Asset 1.png" alt="coinSafe" />
                </a>

                <div className="flex space-x-2 text-lg max-lg:text-[1rem] max-md:hidden">

                </div>
                <button onClick={() => setMenuOpen( oldM => !oldM )} className="flex items-center justify-center cursor-pointer md:hidden z-[999]" >
                    <svg className={`h-[32px] text-gold-300 prepshow ${menuOpen ? 'hidden' : 'showSmooth'}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 18L20 18" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
                        <path d="M4 12L20 12" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
                        <path d="M4 6L20 6" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
                    </svg>
                    <svg className={`h-[32px] prepshow ${menuOpen ? 'showSmooth' : 'hidden'} text-gold-300`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h48v48H0z" fill="none" />
                        <g id="Shopicon">
                            <g>
                                <polygon fill="currentColor" points="24,29.171 9.414,14.585 6.586,17.413 24,34.827 41.414,17.413 38.586,14.585" />
                            </g>
                        </g>
                    </svg>
                </button>
            </m.div>
        
        <div className={`fixed w-full left-0 top-0 h-screen md:hidden ${menuOpen ? '' : 'pointer-events-none'}`}>
            <m.div
                initial={'closed'}
                animate={menuOpen ? 'open' : 'closed'}
                variants={menu}
                className={`overflow-hidden text-right text-[1.8rem] bg-[#0a0a0a] h-full`}
            >
                <Sidebar theme={sidebarTheme} aria-label="CoinSafe">
                    <Sidebar.Items>
                        <Sidebar.ItemGroup>
                            <Sidebar.Item onClick={handleItemClick} href="#overview">
                                Overview
                            </Sidebar.Item>
                            <Sidebar.Collapse label="Components of the System" href="#components-of-the-system#platform">
                                <Sidebar.Item onClick={handleItemClick} href="#platform">Platform</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#coinsafe-api">CoinSafe API</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#sui-blockchain">Sui Blockchain</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#smart-contracts">Smart Contracts</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#decentralized-identifier">Decentralized Identifier</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#12-word-seed-phrase">12-Word Seed Phrase</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#coinsafe-app">CoinSafe App</Sidebar.Item>
                            </Sidebar.Collapse>
                            <Sidebar.Item onClick={handleItemClick} href="#full-system-flow">
                                Full System Flow
                            </Sidebar.Item>
                            <Sidebar.Item onClick={handleItemClick} href="#linking-process-with-external-platforms">
                                Linking Process with External Platforms
                            </Sidebar.Item>
                            <Sidebar.Collapse label="Security Considerations">
                                <Sidebar.Item onClick={handleItemClick} href="#security-decentralized-identifiers">Decentralized Identifiers</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#security-12-word-seed-phrase">12-Word Seed Phrase</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#security-authorization-tokens">Authorization Tokens</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#security-cryptographic-signatures">Cryptographic Signatures</Sidebar.Item>
                                <Sidebar.Item onClick={handleItemClick} href="#security-rate-limiting">Rate Limiting and Transaction Monitoring</Sidebar.Item>
                            </Sidebar.Collapse>
                        </Sidebar.ItemGroup>
                    </Sidebar.Items>
                </Sidebar>
            </m.div>
            
        </div>
        </header>
    </>
}

export default Header