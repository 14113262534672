import Hero from "./components/Hero/Hero"
import { LazyMotion, domAnimation } from "framer-motion";
import StepByStep from "./components/StepByStep/StepByStep";
import Comparison from "./components/Comparison/Comparison";
import Roadmap from "./components/Roadmap/Roadmap";
import Footer from "../Footer/Footer";
import About from "./components/About/About";
import Header from "./components/Header/Header";


const Main = () => {
    return <>
        <div className="w-full">
            <LazyMotion features={domAnimation} strict>
                <Header/>
                <main className="w-full">
                    <div className="max-w-7xl mx-auto w-full flex flex-col">
                        <Hero/>
                        <About/>
                        <StepByStep/>
                        <Comparison/>
                        <Roadmap/>
                    </div>
                </main>
                <Footer/>
            </LazyMotion>
        </div>
    </>
    
}

export default Main