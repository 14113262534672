import { Route, Routes } from 'react-router-dom';
import Main from '../Main/Main';
import { config } from '../config';
import Docs from '../Docs/Docs';


function App() {
  return <>
    <Routes>
      <Route
        path='/'
        element={
          config.path === "main" ? (
            <Main/>
          ) : (
            <Docs/>
          )
        }
      />
      <Route
        path='/*'
        element={
          config.path === "main" ? (
            <Main/>
          ) : (
            <Docs/>
          )
        }
      />
    </Routes>
  </>
}

export default App